interface Project {
  name: string;
  link: string;
  image: string;
  category: "Science" | "DB(less)" | "Engineering" | "Computing" | "Other";
}

export const projects: Project[] = [
  {
    name: "WormWoody Weather Station",
    link: "https://wormwoody.eu",
    image: require("../assets/wormwoody_cover.png"),
    category: "Engineering",
  },
];
