interface Post {
    title: string;
    description: string;
    shortDesc: string;
    image: string;
    category: "Science" | "DB(less)" | "Engineering" | "Computing" | "Gaming" | "Manga" | "Other";
}

export const posts: Post[] = [
    {
        title: "WormWoody Weather Station",
        description: "https://wormwoody.eu",
        shortDesc: "My man Lil' Tommy right here 😸 ",
        image: "https://www.looper.com/img/gallery/voldemorts-most-positive-qualities-in-harry-potter/l-intro-1627529429.jpg",
        category: "Other",
    },
    {
        title: "Good Suramaritan",
        description: "https://wormwoody.eu",
        shortDesc: "We all know how this ends 🙀 ",
        image: require("../assets/post_1_thumbnail.jpg"),
        category: "Gaming",
    },
];
