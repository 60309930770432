// import ReactPlayer from "react-player/lazy";
import { useRef, useState, useEffect } from "react";

const AudioPlayer = () => {
    const playerRef = useRef<HTMLAudioElement>(null);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0.5);
    const [showPlaylist, setShowPlaylist] = useState(false);
    const [showDisplay, setShowDisplay] = useState(false);
    const playlist = [
        "https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3",
        "https://storage.googleapis.com/media-session/sintel/snow-fight.mp3",
        "https://storage.googleapis.com/media-session/big-buck-bunny/prelude.mp3"
    ];

    useEffect(() => {
        const audio = playerRef.current!;

        const handleTimeUpdate = () => {
            setCurrentTime(audio.currentTime);
        };

        const handleLoadedMetadata = () => {
            setDuration(audio.duration);
        };

        audio.addEventListener("timeupdate", handleTimeUpdate);
        audio.addEventListener("loadedmetadata", handleLoadedMetadata);

        return () => {
            audio.removeEventListener("timeupdate", handleTimeUpdate);
            audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        };
    }, []);

    useEffect(() => {
        const audio = playerRef.current!;
        if (isPlaying) {
            audio.play();
        } else {
            audio.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        const audio = playerRef.current!;
        audio.volume = volume;
    }, [volume]);

    const handlePlay = () => {
        setIsPlaying((prevState) => !prevState);
    };

    const handleNext = () => {
        if (currentTrackIndex < playlist.length - 1) {
            setCurrentTrackIndex((prevIndex) => prevIndex + 1);
            setIsPlaying(true);
        }
    };

    const handlePrevious = () => {
        if (currentTrackIndex > 0) {
            setCurrentTrackIndex((prevIndex) => prevIndex - 1);
            setIsPlaying(true);
        }
    };

    const handleEnded = () => {
        if (currentTrackIndex < playlist.length - 1) {
            setCurrentTrackIndex((prevIndex) => prevIndex + 1);
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    };

    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
    };

    const togglePlaylist = () => {
        setShowPlaylist((prev) => !prev);
    };

    const toggleDisplay = () => {
        setShowDisplay((prev) => !prev);
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    return (
        <div className="player-wrapper">
            <div className="simple-audio-player" id="simp">
                <div className="simp-player">
                    <audio
                        ref={playerRef}
                        preload="auto"
                        onEnded={handleEnded}
                        src={playlist[currentTrackIndex]}
                        onTimeUpdate={() => setCurrentTime(playerRef.current?.currentTime || 0)}
                        onLoadedMetadata={() => setDuration(playerRef.current?.duration || 0)}
                    />
                    <div className="simp-display" style={{ display: showDisplay ? 'block' : 'none' }}>
                        <div className="simp-album w-full flex-wrap">
                            <div className="simp-cover"><i className="fa fa-music fa-5x"></i></div>
                            <div className="simp-info">
                                <div className="simp-title">Title</div>
                                <div className="simp-artist">Artist</div>
                            </div>
                        </div>
                    </div>
                    <div className="simp-controls flex-wrap flex-align">
                        <div className="simp-plauseward flex flex-align">
                            <button title="Previous" type="button" className="simp-prev fa fa-backward" onClick={handlePrevious} disabled={currentTrackIndex === 0}></button>
                            <button title={isPlaying ? "Pause" : "Play"} type="button" className={`simp-plause fa ${isPlaying ? 'fa-pause' : 'fa-play'}`} onClick={handlePlay}></button>
                            <button title="Next" type="button" className="simp-next fa fa-forward" onClick={handleNext} disabled={currentTrackIndex === playlist.length - 1}></button>
                        </div>
                        <div className="simp-tracker simp-load">
                            <input title="progressBar" className="simp-progress" type="range" min="0" max="100" value="0" disabled />
                            <div className="simp-buffer"></div>
                        </div>
                        <div className="simp-time flex flex-align">
                            <span className="start-time">{formatTime(currentTime)}</span>
                            <span className="simp-slash">&nbsp;/&nbsp;</span>
                            <span className="end-time">{formatTime(duration)}</span>
                        </div>
                        <div className="simp-volume flex flex-align">
                            <button title="volume" type="button" className="simp-mute fa fa-volume-up"></button>
                            <input title="Volume" className="simp-v-slider" type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} />
                        </div>
                        <div className="simp-others flex flex-align">
                            <button type="button" className="simp-plext fa fa-play-circle" title="Auto Play"></button>
                            <button type="button" className="simp-random fa fa-random" title="Random"></button>
                            <div className="simp-shide">
                                <button type="button" className="simp-shide-top fa fa-caret-up" onClick={toggleDisplay} title="Show/Hide Album"></button>
                                <button type="button" className="simp-shide-bottom fa fa-caret-down" onClick={togglePlaylist} title="Show/Hide Playlist"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="simp-playlist" style={{ display: showPlaylist ? 'block' : 'none' }}>
                    <ul>
                        {playlist.map((track, index) => (
                            <li key={index} className={index === currentTrackIndex ? 'simp-active' : ''}>
                                <span className="simp-source" data-src={track}>{track}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="simp-footer">Made with 💖 &amp; 🙌 by <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" title="The Master-Mind" rel="noopener noreferrer">The Master-Mind</a></div>
            </div>
        </div>
    );
};

export default AudioPlayer;