import { projects } from "./projects";
import { posts } from "./posts";

const commands: Map<string, string> = new Map<string, string>();

commands.set("motd", motdText());
commands.set("about", aboutText());
commands.set("projects", projectsText());
commands.set("blog", postsText());
commands.set("contact", openLink("mailto:abonev1995@gmail.com"));
commands.set("help", helpText());

export const getCommandByName = (name: string): string => {
  name = name.trim().toLowerCase().split(" ")[0];

  // commands that require redirecting
  switch (name) {
    case "contact":
      window.open("mailto:abonev1995@gmail.com", "_blank");
      break;
  }

  return commands.get(name) ?? `
  <br>
  <span style="color: #A34545;">${name}</span>: command not found`;
};

export const getCommandNames = (): string[] => {
  const commandNames: string[] = ["clear"];
  for (const entry of Array.from(commands.entries())) {
    commandNames.push(entry[0]);
  }

  return commandNames.sort();
};

export function motdText(): string {
  return `
    <br>
    Type <span class="text-kali-green-dark relative">'help'</span> to see the list of available commands.
  `;
}

function helpText(): string {
  const commandNames: string[] = ["help"];
  for (const entry of Array.from(commands.entries())) {
    commandNames.push(entry[0]);
  }

  return `
    <br>
    Available commands:
    <br>

    &nbsp;&nbsp;&nbsp;
    ${commandNames.sort().map(command => `<span class="text-kali-green-dark relative">'${command}'</span><br>
    &nbsp;&nbsp;&nbsp;&nbsp;`).join("")}
    <br>

    Press <span class='keys'>[Tab]</span> for auto completion.<br>
    Press <span class='keys'>[Ctrl] + [L]</span> to clear the terminal.<br>
    <div style="text-decoration: line-through;">Press <span class='keys'>[↑][↓]</span> to scroll through your history of commands</div>
  
    
  
    `;
}

function aboutText(): string {
  return `
    <br>
    Hello, root!<br><br>

    Meet the funky fusion of wires and tunes, a.k.a. '<span class="terminal-bold">The Master-Mind</span>'!<br><br>
    By day, I'm weaving magic as an <span class="terminal-bold">automation engineer</span>, <span class="terminal-bold">hardware programmer</span>, and occasional <span class="terminal-bold">software sorcerer</span>.
    But when the circuits cool down, you'll find me jamming away on my air drums, proudly wearing my dad hat, and making my wife laugh with some top-notch dad jokes... Yeah, right.<br><br>

    Let's automate the groove and program some smiles together!<br>
    Would you like to connect with me?<br>
    &middot;&nbsp;Enter the <span class="text-kali-green-dark relative">'contact'</span> command!<br>
    Want to take a look at my projects?<br> 
    &middot;&nbsp;Enter the <span class="text-kali-green-dark relative">'projects'</span> command!
  `;
}

function openLink(link: string): string {
  return `
    <br>
    Redirecting to 
      <a class="terminal-link" href="${link}" target="_blank" rel="noreferrer">
        ${link}</a>...
    `;
}

function projectsText(): string {
  return `
    ${projects
      .map((project) => {
        return `
        <br>
        <div class="blog-card" >
          <img class="blog-img" src="${project.image}" alt="thumbnail"/>
          <div class="text-overlay" >
            <h2>${project.name}</h2>
            <p class="project-${project.category}"><a href="${project.link}" target="_blank" class="read-more">Open WormWoody</a></p>
          </div>
        </div>`;
      })
      .join("&nbsp;&nbsp;&nbsp;")}
  `;
}

function postsText(): string {
  return `
    ${posts
      .map((post) => {
        return `
        <br>
        <div class="blog-card">
          <img class="blog-img" src="${post.image}" alt="thumbnail"/>
          <div class="text-overlay">
            <h2>${post.title}</h2>
            <p>${post.shortDesc}... <br><a href="#" class="read-more">Read More</a></p>
          </div>
        </div>`;
      })
      .join("&nbsp;&nbsp;&nbsp;")}
  `;
}